import React from 'react'

const Clients = ({ siteTitle }) => (
  <div className="my-8">
    <h2 className="my-2 text-xl text-color">Organizations I worked with and for</h2>
    <p className="my-2 leading-normal text-lg text-color">I am grateful that I had the chance to work with some big companies directly and indirectly, but as they say, size really doesn't matter. I love to work with small startups and agencies too.</p>

    <ul className="w-full clients-list list-reset flex px-0 mx-0 justify-around items-center overflow-x-scroll ">
      <li className="mx-4 lg:mx-0"><img className="client-image-logo" src="/images/clients-logo/honda.png" alt="Honda"/></li>
      <li className="mx-4 lg:mx-0"><img className="client-image-logo" src="/images/clients-logo/natwest.png" alt="natwest"/></li>
      <li className="mx-4 lg:mx-0"><img className="client-image-logo" src="/images/clients-logo/tedbaker.png" alt="tedbaker"/></li>
      <li className="mx-4 lg:mx-0"><img className="client-image-logo" src="/images/clients-logo/sony.png" alt="sony"/></li>
      <li className="mx-4 lg:mx-0"><img className="client-image-logo" src="/images/clients-logo/rbs.png" alt="rbs"/></li>
    </ul>
  </div>
)

export default Clients
