import React from 'react'
import { withPrefix } from 'gatsby'
// import styles from "../styles/about.module.scss"
import Layout from '../components/layout'
import Clients from '../components/clients'

const About = () => (
  <Layout>
    <div className="mx-6">
      <h1 className="mt-6 mb-4">About Me </h1>
      <p className="my-3 leading-normal text-lg text-color">
        My specialty lies in the crafting highly interactive experiences for the
        web and mobile platform. I provide progressive art direction, design and
        programming services for design firm, advertising agencies and business
        looking to create memorable experiences that create and reinforce brand
        recognition.
      </p>
      <p className="my-3 leading-normal text-lg text-color">
        Ideas are my bread n butter. I mold and shape them into living breathing
        interactive experiences and captivate. I believe that great interactive
        ideas drive on the tension between design and code.
      </p>
      <p className="my-3 leading-normal text-lg text-color">
        Since 1998's, I’ve been fortunate to be involved in a variety of
        projects — from developing desktop application in{" "}
        <span className="text-highlight text-color">Visual Basic,</span>{" "}
        <span className="text-highlight text-color">Foxpro</span>, web based using{" "}
        <span className="text-highlight text-color">Java</span>,{" "}
        <span className="text-highlight text-color">Python</span>,{" "}
        <span className="text-highlight text-color">ASP</span>,{" "}
        <span className="text-highlight text-color">PHP</span> to mobile application using{" "}
        <span className="text-highlight text-color">Java</span>,
        <span className="text-highlight text-color">Swift</span> and now{" "}
        <span className="text-highlight text-color">React Native</span>. While I still like
        to get my hands dirty, my work is focused on research, strategy,
        addressing organizational challenges and helping other developer do the
        work.
      </p>
      <img src={withPrefix('/images/about.png')} className="mt-8 mb-2" alt="About me" />
      <Clients/>
    </div>
  </Layout>
)

export default About
